import toLower from 'lodash/toLower';

export const DL_STATUSES = {
  waitingForValidation: 'waiting_for_validation',
  validated: 'validated',
  rejected: 'rejected',
  expired: 'expired',
  incomplete: 'incomplete',
};

export const DL_ACTION = {
  replace: 'Replace',
  upload: 'Upload',
};

export const DL_TEXTS = {
  requiredField: 'Required field',
  completeAllFields: 'All required fields should be completed',
};

export const isUK = countryName => toLower(countryName) === 'united kingdom';

export const isSpain = countryName => toLower(countryName) === 'spain';

export const spanishDrivingLicense = new RegExp(/^([XYZ][0-9]{7}[A-Z]|[0-9]{8}[A-Z]|[ABCDEFGHJKLMNPQRSUVW][0-9]{7}[A-Z])$/);
